<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos10_26.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>Inline reflow soldering under vacuum</h2>
                  <br />
                  <h4>
                    Ersa answers the demands of electronics manufacturing for
                    pore-free solder joints - especially for power electronics
                    and high-reliability technology - with the EXOS 10/26, a
                    convection reflow soldering system with 22 heating chambers
                    and 4 cooling zones as well as a vacuum chamber after the
                    peak zone, which can reduce the void rate by an impressive
                    99%. Thanks to intelligent features, the EXOS 10/26 can
                    produce particularly economically and "void-free".
                  </h4>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="mt-4">
                  <br /><br /><br /><br />

                  <h1>Technology Highlights</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        4-part conveyor (infeed, preheating and peak, vacuum,
                        cooling zone) also as dual track
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Perfect synchronization of the assemblies and
                        transitions thanks to sensor-monitored conveyor, no
                        external infeed module required
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Maintenance-friendly and lubricant-free roller conveyor
                        in the vacuum module
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Optimum access to the vacuum chamber through lifting
                        unit from above
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Optimum temperature profiles through medium-wave
                        emitters in the vacuum module
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Maximum machine availability thanks to fast removal of
                        the conveyor unit in the vacuum module
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Part-integrated vacuum pump on separate module carrier
                        for easy and fast maintenance
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Innovative cleaning system SMART ELEMENTS®</h5>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos highlight.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />

            <div class="row container" style="font-size: 18px">
              <div class="container-fluid">
                <div>
                  <div class="">
                    <div class="">
                      <div>
                        <b-carousel
                          id="carousel-1"
                          v-model="slide"
                          :interval="2000"
                          controls
                          indicators
                          background="#fff"
                          style="text-shadow: 1px 1px 2px #333"
                          @sliding-start="onSlideStart"
                          @sliding-end="onSlideEnd"
                        >
                          <div class="carousel-item" active>
                            <div class="parent d-flex justify-content-center">
                              <img
                                class="d-block img-fluid"
                                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos1.jpg"
                                alt="category.name"
                              />
                            </div>
                          </div>
                          <div class="carousel-item">
                            <div class="parent d-flex justify-content-center">
                              <img
                                class="d-block img-fluid"
                                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos2.jpg"
                                alt="category.name"
                              />
                            </div>
                          </div>

                          <div class="carousel-item">
                            <div class="parent d-flex justify-content-center">
                              <img
                                class="d-block img-fluid"
                                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos3.jpg"
                                alt="category.name"
                              />
                            </div>
                          </div>
                          <div class="carousel-item">
                            <div class="parent d-flex justify-content-center">
                              <img
                                class="d-block img-fluid"
                                src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/exos4.jpg"
                                alt="category.name"
                              />
                            </div>
                          </div>
                        </b-carousel>
                      </div>
                    </div>
                  </div>
                </div>

                <br /><br />
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                    <h1><strong>Technical data</strong></h1>
                  </div>
                  <div class="col-3"></div>
                </div>
                <br />
                <b-tabs pills card vertical>
                  <b-tab active title="Dimensions">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Length:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Height:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>7.875 mm</h4></div>
                      <div class="col"><h4>1.919 mm</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Width:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Weight:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>7.2.195 mm</h4></div>
                      <div class="col"><h4>approx. 4.000 kg</h4></div>
                    </div>
                  </b-tab>

                  <b-tab title="Conveyor system">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Working width:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Transport speed:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>60-630 mm</h4></div>
                      <div class="col"><h4>20-200 cm/min</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3>
                          <strong>Working width (centre support):</strong>
                        </h3>
                      </div>
                      <div class="col">
                        <h3><strong>Transport over ground:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>100-630 mm</h4></div>
                      <div class="col"><h4>820-980 mm</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Throughput height:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Pin chain transport:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>+/-35 mm</h4></div>
                      <div class="col"><h4>5 mm Auflage</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Centre support, strap height:</strong></h3>
                      </div>
                      <div class="col"></div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>15 mm</h4></div>
                    </div>
                  </b-tab>
                  <b-tab title="Process zone">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Infeed:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Cooling zone:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>1.011 mm</h4></div>
                      <div class="col"><h4>2.273 mm</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Heating zone:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Outlet:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>4.545,5 mm</h4></div>
                      <div class="col"><h4>730 mm</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Vacuum zone:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Process chamber width:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>558 mm</h4></div>
                      <div class="col"><h4>ca. 910 mm</h4></div>
                    </div>
                  </b-tab>
                  <b-tab title="Heating system">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3>
                          <strong>
                            Convection rate (except vacuum chamber):</strong
                          >
                        </h3>
                      </div>
                      <div class="col">
                        <h3><strong>Soldering zone:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>100%</h4></div>
                      <div class="col"><h4>2 top/2 bottom</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Volumetric flow/module:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Nominal power/module:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>500 m³/h adjustable, Multijet</h4>
                      </div>
                      <div class="col"><h4>3,3/4,5/9,0 kW</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Convection heating modules:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Heating system vacuum chamber:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>11 top/11 bottom</h4></div>
                      <div class="col"><h4>2x regulated (4,8/6,8 kW)</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Preheating:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>3 medium wave heaters:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>9 up/9 down</h4></div>
                      <div class="col"><h4>1x set (4 kW)</h4></div>
                    </div>
                  </b-tab>
                  <b-tab title="Vacuum chamber">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3>
                          <strong> Chamber volume:</strong>
                        </h3>
                      </div>
                      <div class="col">
                        <h3><strong>Ultimate pressure:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>approx. 110 l</h4></div>
                      <div class="col"><h4>up to 10 mbar</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>Pump capacity:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong></strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>up to 240 m³/h or 5.3 kW</h4>
                      </div>
                      <div class="col"><h4></h4></div>
                    </div>
                    <br /><br />
                  </b-tab>
                  <b-tab title="Electrical data">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong> Voltage:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Nominal power:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>5-wire network, 3x 400 V, N, PE</h4>
                      </div>
                      <div class="col"><h4>118-150 W</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong> Voltage tolerance:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Power reduced:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>+/-10%</h4></div>
                      <div class="col"><h4>69 kW</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong> Frequency:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong>Continuous power in operation:</strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col"><h4>50/60 Hz</h4></div>
                      <div class="col"><h4>ca. 14-25 kW</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong>max. back-up fuse:</strong></h3>
                      </div>
                      <div class="col">
                        <h3><strong></strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>3x 125 A</h4>
                      </div>
                      <div class="col"><h4></h4></div>
                    </div>
                    <br /><br />
                  </b-tab>
                  <b-tab title="Exhaust">
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3><strong> Exhaust nozzle:</strong></h3>
                      </div>
                      <div class="col">
                        <h3>
                          <strong>Exhaust air monitoring per nozzle:</strong>
                        </h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>2 pieces, each 150 mm (diameter)</h4>
                      </div>
                      <div class="col"><h4>integrated</h4></div>
                    </div>
                    <br /><br />
                    <div
                      class="row"
                      style="margin-bottom: 5px; margin-left: 10%"
                    >
                      <div class="col">
                        <h3>
                          <strong>Extraction capacity per nozzle:</strong>
                        </h3>
                      </div>
                      <div class="col">
                        <h3><strong></strong></h3>
                      </div>
                    </div>
                    <div class="row" style="margin-left: 10%">
                      <div class="col">
                        <h4>400 m3/h</h4>
                      </div>
                      <div class="col"><h4></h4></div>
                    </div>
                    <br /><br />
                  </b-tab>
                </b-tabs>
                <br><br>
                 <!-- video -->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6">
                          <!-- 16:9 aspect ratio -->
                          <div class="ratio ratio-21x9">
                            <iframe
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/_7KolIRmX0Q"
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>

                        <div class="col-xl-4">
                          <h2>EXOS 10/26: Ersa reflow soldering under vacuum³</h2>
                          <h5>With the EXOS 10/26, Ersa presents a vacuum reflow soldering system with which the void rate can be reduced by 99%.</h5>
                        </div>
                        <!-- end col -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end col -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>